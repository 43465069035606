/*
 * @Date: 2023-03-13 17:18:22
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 12:29:06
 * @FilePath: \J1-M1-QRP-CODE\components\modal\index.js
 */
import React from "react";

class Modal extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }


    close(){
        this.props.closeModal();
    }
    render(){
        let { isModal , error_text , title } = this.props;
        return (
            
            // 模态窗
            <>
                <div className="modal" style={{display:isModal ? 'block' : 'none'}}>
                    <div className="modal-box">
                        <button className="btn" onClick={()=>{this.close()}}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="" class="fill-current text-gray-300">
                                <path d="M18 1.81286L16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286Z" fill="" fill-opacity=""></path>
                            </svg>
                        </button>
                        <div className="header">{title || '错误'}</div>
                        <div className="center">{error_text}</div>
                    </div>
                </div>

                
            </>

        )
    }

}

export default Modal;