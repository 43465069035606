/*
 * @Date: 2023-03-13 17:16:39
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-17 19:26:31
 * @FilePath: \F1-M1-QRP-CODE\config\common\reg.js
 */
// 用户名格式正则
export const RegName = /^[a-zA-Z0-9_]{5,16}$/;
// 密码格式正则
export const RegPassword = /(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=[\^#$@]*)([a-zA-Z0-9]([\^#$@]*))+$/;
// 电话号码格式正则
export const RegPhone = /^[1-9]{1}[0-9]{10}$/;
// 电子邮箱格式正则
export const RegEmail = /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*/;