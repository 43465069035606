/*
 * @Date: 2023-03-22 10:20:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-04 16:01:42
 * @FilePath: \J1-M1-QRP-CODE\components\hayhar\footer.js
 */
import React from "react";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            end_page_list: [
                {
                    title: '关于我们',
                    href: '/Help/?key=1',
                    hrefMobile: '/user/helpCenter',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: '规则条款',
                    href: '/Help/?key=2',
                    hrefMobile: '/user/helpCenter',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: '帮助中心',
                    href: '/Help/?key=3',
                    hrefMobile: '/user/helpCenter',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: '联系我们',
                    href: '/Help/?key=4',
                    hrefMobile: '/user/helpCenter',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: '合作加盟',
                    href: '',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'APP下载',
                    href: '/Download',
                    _qaq: () => {
                        global.globalGtag && global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                }
            ]
        }
    }

    isMobileDevice = () => {
        return typeof window !== "undefined" && window.innerWidth <= 768;
    };

    render() {
        let { end_page_list } = this.state;
        let { mainSiteUrl, affiliateUrlLM, pathname, addAffiliateParamToUrl } = this.props;
        const isMobile = this.isMobileDevice();

        return (
            <>
                <div className="footer-web">
                    <div className="footer-content-web">
                        
                        {/* 品牌赞助 */}
                        <div className="sponsorship">
                            <p>品牌赞助</p>
                            <img src="/static/images/m1-001/jbo-sponsor.png" />
                        </div>
                        {/* 合作伙伴 */}
                        <div className="partner">
                            <p>合作伙伴</p>
                            {pathname === 'm1-005' || pathname === 'm1-004' ? <img src="/static/images/m1-005/license.png" /> : <img src="/static/images/m1-001/license-2-3.webp" />}
                        </div>
                        {/* 支付平台 */}
                        <div className="paymentplatform">
                            <p>支付平台</p>
                            <img src="/static/images/m1-001/jbo-pay-cn.png" />
                        </div>
                        
                    </div>

                    <div className="footer-end_list">
                        <div className={`${pathname === 'm1-005' || pathname === 'm1-004' ? "footer-end_list-box-005" : "footer-end_list-box"}`}>
                            {end_page_list.map((val, index) => {
                                // Determine the correct href based on device type
                                let href = isMobile ? val.hrefMobile : val.href;
                                // Construct the full URL based on mainSiteUrl and the affiliate URL logic
                                let fullUrl = href ? (mainSiteUrl ? mainSiteUrl + href : '') : (affiliateUrlLM ? affiliateUrlLM : '');
                                // Add the 'aff' parameter if the 'CO_affiliate' cookie is present
                                fullUrl = addAffiliateParamToUrl(fullUrl);
                                console.log("whats this",fullUrl)
                                return (
                                    <a key={index} target="_blank" onClick={() => { val._qaq() }} href={fullUrl} className="item">
                                        {val.title}
                                    </a>
                                );
                            })}
                        </div>
                        
                        <span>Copyright © 2011-2023 竞博JBO All Rights Reserved</span>
                    </div>
                </div>
                
            </>
        )
    }
}

export default Footer;
