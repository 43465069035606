/*
 * @Date: 2023-03-17 15:20:59
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-10 09:57:16
 * @FilePath: \J1-M1-QRP-CODE\config\default\banner.js
 */

export const bannerConfigs = {

    'm1-001' : [{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-1.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-2.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-3.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-4.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-5.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-5.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-6.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-6.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm1-004' : [{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-1.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-2.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-3.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-4.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-5.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-5.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-6.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-6.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm1-005' : [{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-1.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-2.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-3.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-4.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-5.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-5.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-001/WEB-6.png',
        cmsImageUrlMobile: '/static/images/m1-001/Mob-6.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
};