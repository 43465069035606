/*
 * @Date: 2023-04-25 10:18:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 10:29:03
 * @FilePath: \J1-M1-QRP-CODE\config\trace\affDomains.js
 */

//J1M1 沒有指定affcode 有需要再加入
const affDomains = {
    //"localhost" : 'J106542',
    // "jbo88.biz" : 'J106542',
    // "h8x6.com"  : 'J106542',
    // "y6f2.com"  : 'J106543',
    // "oj26b.com" : 'J101440',
    // "j6khe.com" : 'J101189',
    // "j77pl.com" : 'J101469',
    // "jy192.com" : 'J101187',
}

export default affDomains;
