/*
 * @Date: 2023-03-15 18:06:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-05 11:32:45
 * @FilePath: \J1-M1-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 *
 */
const traceConfigs = {
    'm1-001': {
        piwik : '06b4ddc9-6c4e-429d-840e-af05b36df874',
        "51la" : 21337575 ,
        ga : false,
    },
    'm1-004': {
        piwik : '06b4ddc9-6c4e-429d-840e-af05b36df874',
        "51la" : 21337589 ,
        ga : false,
    },
    'm1-005': {
        piwik : '06b4ddc9-6c4e-429d-840e-af05b36df874',
        "51la" : 21337581 ,
        ga : false,
    },
}

export default traceConfigs;
